import CONSTANTS from '../../constants';

export function bindiscounts(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.BINDISCOUNTS.GET_BINDISCOUNT) {
    const { bindiscount } = action;
    const promObjs = state ? state : {};

    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] = [];
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] = [];
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] = {};
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] = {};

    if (bindiscount.errorMessage) {
      promObjs['currentManualBinID'] = { errorMessage: bindiscount.errorMessage, context: bindiscount.context };
      return promObjs;
    }
    promObjs['currentManualBinID'] = {
      id: bindiscount.id, code: bindiscount.code, isStack: bindiscount.isStack, bank_card_name: bindiscount.bank_card_name,
      bank_card_type: bindiscount.bank_card_type,
      bank_card_variant: bindiscount.bank_card_variant,
      max_discount_amount: bindiscount.max_discount_amount
    };
    const {
      BindiscountDiscount: { discount_type, BindiscountDiscountItems: bindiscountDiscountItems },
      BindiscountOrderTypes
    } = bindiscount;
    const orderTypeIDs = BindiscountOrderTypes.map((o) => o.order_type_id);
    if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.BASKET) {
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET].push(bindiscount);
    } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.ALL_ITEMS) {
      promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS].push(bindiscount);
    } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.SPECIFIC_ITEMS) {
      for (let i = 0; i < bindiscountDiscountItems.length; i++) {
        const item = bindiscountDiscountItems[i];
        if (item.item_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.ITEM_TYPES.ITEM) {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS][item.item] = {
            ...item,
            parent_id: bindiscount.id,
            max_discount: bindiscount.max_discount_amount,
            order_type_ids: orderTypeIDs,
            code: bindiscount.code,
            discount_amount: bindiscount.BindiscountDiscount.discount_amount ? bindiscount.BindiscountDiscount.discount_amount : item.discount_amount,
            discount_amount_type: bindiscount.BindiscountDiscount.discount_amount_type,
            bank_img_url: bindiscount.bank_img_url,
            bank_card_name: bindiscount.bank_card_name,
            bank_card_type: bindiscount.bank_card_type,
            bank_card_variant: bindiscount.bank_card_variant,
            ...bindiscount
          };
        } else {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES][item.item] = {
            ...item,
            parent_id: bindiscount.id,
            max_discount: bindiscount.max_discount_amount,
            order_type_ids: orderTypeIDs,
            code: bindiscount.code,
            discount_amount: bindiscount.BindiscountDiscount.discount_amount ? bindiscount.BindiscountDiscount.discount_amount : item.discount_amount,
            discount_amount_type: bindiscount.BindiscountDiscount.discount_amount_type,
            bank_img_url: bindiscount.bank_img_url,
            bank_card_name: bindiscount.bank_card_name,
            bank_card_type: bindiscount.bank_card_type,
            bank_card_variant: bindiscount.bank_card_variant,
            ...bindiscount
          };
        }
      }
    }
    return promObjs;
  }
  if (action.type === CONSTANTS.ACTIONS.BINDISCOUNTS.LIST) {
    const { bindiscounts } = action;
    const promObjs = {};
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET] = [];
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS] = [];
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES] = {};
    promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS] = {};

    for (let i = 0; i < bindiscounts.length; i++) {
      const bindiscount = bindiscounts[i];
      if (bindiscount?.BindiscountDiscount) {
        const {
          BindiscountDiscount: { discount_type, BindiscountDiscountItems: bindiscountDiscountItems },
          BindiscountOrderTypes
        } = bindiscount;
        const orderTypeIDs = BindiscountOrderTypes.map((o) => o.order_type_id);
        if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.BASKET) {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.BASKET].push(bindiscount);
        } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.ALL_ITEMS) {
          promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ALL_ITEMS].push(bindiscount);
        } else if (discount_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE.SPECIFIC_ITEMS) {
          for (let i = 0; i < bindiscountDiscountItems.length; i++) {
            const item = bindiscountDiscountItems[i];
            if (item.item_type === CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.ITEM_TYPES.ITEM) {
              promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.ITEMS][item.item + bindiscount.code] = {
                ...item,
                parent_id: bindiscount.id,
                max_discount: bindiscount.max_discount_amount,
                order_type_ids: orderTypeIDs,
                code: bindiscount.code,
                discount_amount: bindiscount.BindiscountDiscount.discount_amount ? bindiscount.BindiscountDiscount.discount_amount : item.discount_amount,
                discount_amount_type: bindiscount.BindiscountDiscount.discount_amount_type,
                bank_img_url: bindiscount.bank_img_url,
                bank_card_name: bindiscount.bank_card_name,
                bank_card_type: bindiscount.bank_card_type,
                bank_card_variant: bindiscount.bank_card_variant,
                ...bindiscount
              };
            } else {
              promObjs[CONSTANTS.PROMOTIONS.VARIABLES.DISCOUNTS.TYPE_STR.CATEGORIES][item.item + bindiscount.code] = {
                ...item,
                parent_id: bindiscount.id,
                max_discount: bindiscount.max_discount_amount,
                order_type_ids: orderTypeIDs,
                code: bindiscount.code,
                discount_amount: bindiscount.BindiscountDiscount.discount_amount ? bindiscount.BindiscountDiscount.discount_amount : item.discount_amount,
                discount_amount_type: bindiscount.BindiscountDiscount.discount_amount_type,
                bank_img_url: bindiscount.bank_img_url,
                bank_card_name: bindiscount.bank_card_name,
                bank_card_type: bindiscount.bank_card_type,
                bank_card_variant: bindiscount.bank_card_variant,
                ...bindiscount
              };
            }
          }
        }
      }
    }
    return promObjs;
  }
  return state;
}
