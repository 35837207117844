import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import ReduxPromise from 'redux-promise';
import thunkMiddleware from 'redux-thunk';
import './App.scss';
import './assets/css/orderPref.scss';
import './assets/css/radio.scss';
import PrivateRoute from './components/shared/PrivateRoute';
import ScrollToTop from './components/shared/ScrollToTop';
import Spinner from './components/shared/Spinner';
import CONSTANTS from './constants';
import './index.scss';
import reducers from './reducers';
import AnalyticsService from './services/AnalyticsService';

const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;
const CLEAR_STORAGE = !!process.env.REACT_APP_CLEAR_STORAGE;
if (localStorage.APP_VERSION !== APP_VERSION) {
  if (CLEAR_STORAGE) {
    localStorage.clear();
  }
  localStorage.setItem('APP_VERSION', APP_VERSION);
}
AnalyticsService.logEvent('react_index_loaded_plugd', {
  event: 'react_index_loaded_plugd'
});

const Login = lazy(() => import('./components/sessions/Login'));
const Logout = lazy(() => import('./components/sessions/Logout'));
const NearByLocations = lazy(() => import('./components/locations/NearByLocations'));
const ParentMenu = lazy(() => import('./components/locations/ParentMenu'));
const Menu = lazy(() => import('./components/locations/Menu'));
const Pages = lazy(() => import('./components/locations/Pages'));
const Terms = lazy(() => import('./components/locations/Terms'));
const Loyalty = lazy(() => import('./components/loyalty/Loyalty'));
const EarnedRewards = lazy(() => import('./components/loyalty/EarnedRewards'));
const Cart = lazy(() => import('./components/cart/Cart'));
const PaymentFlow = lazy(() => import('./components/orders/PaymentFlow'));
const CreateOrder = lazy(() => import('./components/orders/CreateOrder'));
const OrderStatus = lazy(() => import('./components/orders/Status'));
const AddReview = lazy(() => import('./components/reviews/AddReview'));
const NotificationsList = lazy(() => import('./components/shared/notifications/NotificationsList'));
const MyOrders = lazy(() => import('./components/my-order/MyOrder'));


// if (process.env.REACT_APP_STAGE !== 'DEV') {
//   Sentry.init({
//     dsn: "https://c0a0b5300a1c5439089714bfbf49297f@o4506235627110400.ingest.us.sentry.io/4506854510231552",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.browserProfilingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Profiling
//     profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }


const createStoreWithMiddleware = applyMiddleware(ReduxPromise, thunkMiddleware)(createStore);
ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <BrowserRouter>
      <ScrollToTop />
      <div className="app-container">
        <Suspense fallback={<Spinner size={40} />}>
          <Switch>
            <Route exact path={`${CONSTANTS.BASE_URL}login`} component={Login} />
            <PrivateRoute exact path={`${CONSTANTS.BASE_URL}logout`} component={Logout} />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}notifications`}
              component={NotificationsList}
            />
            <Route exact path={`${CONSTANTS.BASE_URL}`} component={NearByLocations} />
            <Route exact path={`${CONSTANTS.BASE_URL}master/:location_id`} component={ParentMenu} />
            <Route exact path={`${CONSTANTS.BASE_URL}:location_id/confirm/`} component={Cart} />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/orders/payments/flow`}
              component={PaymentFlow}
            />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/orders/create`}
              component={CreateOrder}
            />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/orders/status`}
              component={OrderStatus}
            />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/my-order`}
              component={MyOrders}
            />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/reviews/new`}
              component={AddReview}
            />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/terms`}
              component={Terms}
            />
            <Route
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/loyalty`}
              component={Loyalty}
            />
            <PrivateRoute
              exact
              path={`${CONSTANTS.BASE_URL}:location_id/earned`}
              component={EarnedRewards}
            />
            <Route
              path={`${CONSTANTS.BASE_URL}:location_id/page/:page_id`}
              component={(props) => <Pages {...props} />}
            />
            <Route
              path={`${CONSTANTS.BASE_URL}:location_id`}
              component={(props) => <Menu {...props} />}
            />

          </Switch>
        </Suspense>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    }).catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}
