import CONSTANTS from '../../constants';

export function item(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ITEMS.GET) return action.item;
  return state;
}

export function itemDetails(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ITEMS.GET_DETAILS) return action.itemDetails;
  return state;
}
