import CONSTANTS from '../../constants';

export function loyalties(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.LOYALTIES.LIST) {
    const { loyalties } = action;

    return loyalties;
  }

  return state;
}
