import moment from 'moment';
import CONSTANTS from '../constants';
import FirebaseNotificationService from './FirebaseNotificationService';
import UserLocationService from './UserLocationService';
export default {
  setLoginData,
  getToken,
  getTokenRefreshTime,
  removeLoginData,
  validateLogin,
  fbLogin,
  phoneLogin,
  createOtpStatus,
  updateOtpStatus,
  transformFbDetails,
  updateProfile,
  sendMail,
  isLoggedIn,
  logout,
  createWhatsAppOTP,
  getWhatsAppOTPDetails,
  createVerification,
  checkVerification,
  getOTPSettings,
  createJazzVerification,
  checkJazzVerification
};

function setLoginData(tokenData) {
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`, tokenData.token);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_userId`, tokenData.userId);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshToken`, tokenData.refreshToken);
  localStorage.setItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshTime`, moment().unix());
  UserLocationService.syncWithServer();
}

function removeLoginData() {
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_userId`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshToken`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshTime`);
  localStorage.removeItem(`${CONSTANTS.CACHE_KEY_PREFIX}_locations`);
}

function getToken() {
  let token = localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_token`);
  if (token)
    return {
      token,
      userId: localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_userId`),
      refreshToken: localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshToken`)
    };
  return null;
}

function getTokenRefreshTime() {
  return localStorage.getItem(`${CONSTANTS.CACHE_KEY_PREFIX}_refreshTime`);
}

async function validateLogin() {
  if (getToken() && getToken().token) {
    try {
      let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.CHECK}`, {
        method: 'POST',
        headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': getToken().token }
      });
      response = await response.json();
      if (!response || !response.valid) removeLoginData();
    } catch (e) {
      console.log(e);
      // removeLoginData();
    }
  }
}

function isLoggedIn() {
  const tokenInfo = getToken();
  const token = tokenInfo ? tokenInfo.token : null;
  if (!token) {
    return false;
  }
  return true;
}

async function fbLogin(fbDetails) {
  let data = transformFbDetails(fbDetails);
  let response = await fetch(`${CONSTANTS.API_URL}${CONSTANTS.URLS.LOGIN.FB}`, {
    method: 'POST',
    body: JSON.stringify(data)
  });
  response = await response.json();
  if (response && response.data && response.data.token)
    return {
      type: response.data.type,
      userId: response.data.user_id,
      token: response.data.token,
      refreshToken: response.data.refresh_token,
      profileEmpty: response.data.profile_empty
    };
  else throw new Error(`Could not fetch token: ${response}`);
}

async function phoneLogin(details) {
  let mobile_fcm = localStorage.getItem('mobile_fcm_token');
  let web_fcm = FirebaseNotificationService.getTokenFromCache();
  let data = { phone_number: details.phoneNumber, mobile_fcm: mobile_fcm && details.loc_id ? mobile_fcm : null, loc_id: details.loc_id, web_fcm: web_fcm && details.loc_id ? web_fcm : null };
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.PHONE}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response && response.data && response.data.token)
    return {
      type: response.data.type,
      userId: response.data.user_id,
      token: response.data.token,
      refreshToken: response.data.refresh_token,
      profileEmpty: response.data.profile_empty
    };
  else throw new Error(`Could not fetch token: ${response}`);
}

async function logout(user_id, loc_id) {
  let platform = localStorage.getItem('platform');
  platform = platform === 'app' ? platform : 'web';
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.LOGOUT}`, {
    method: 'POST',
    body: JSON.stringify({ user_id: user_id, loc_id: loc_id, platform: platform }),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}

async function createWhatsAppOTP(details) {
  let mobile_fcm = localStorage.getItem('mobile_fcm_token');
  let web_fcm = FirebaseNotificationService.getTokenFromCache();
  let data = { phone_number: details.phoneNumber, mobile_fcm: mobile_fcm ? mobile_fcm : null, loc_id: details.loc_id, web_fcm: web_fcm ? web_fcm : null };
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.WHATSAPP_OTP}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}

async function getWhatsAppOTPDetails(details) {
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.WHATSAPP_OTP_STATUS}`, {
    method: 'POST',
    body: JSON.stringify(details),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}
async function createOtpStatus(details) {
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.OTP}`, {
    method: 'POST',
    body: JSON.stringify(details),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}



async function updateOtpStatus(details) {
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.OTPUPDATE}`, {
    method: 'POST',
    body: JSON.stringify(details),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}

async function createVerification(details) {
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.CREATEVERIFICATION}`, {
    method: 'POST',
    body: JSON.stringify(details),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}

async function checkVerification(details) {
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.CHECKVERIFICATION}`, {
    method: 'POST',
    body: JSON.stringify(details),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}

async function createJazzVerification(details) {
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.CREATEJAZZVERIFICATION}`, {
    method: 'POST',
    body: JSON.stringify(details),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}

async function checkJazzVerification(details) {
  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.CHECKJAZZVERIFICATION}`, {
    method: 'POST',
    body: JSON.stringify(details),
    headers: { ...CONSTANTS.REQUEST_HEADERS }
  });
  response = await response.json();
  if (response)
    return response;
  else throw new Error(`Could not fetch token: ${response}`);
}

async function getOTPSettings() {
  let otpSettings = await fetch(
    `${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.OTPSETTINGS}`
  );
  otpSettings = await otpSettings.json();
  if (otpSettings)
    return otpSettings;
  else throw new Error('otp settings not found');
}


function transformFbDetails(fbDetails) {
  return {
    first_name: fbDetails.name ? fbDetails.name.split(' ').slice(0, -1).join(' ') : '',
    last_name: fbDetails.name ? fbDetails.name.split(' ').slice(-1).join(' ') : '',
    screenname: fbDetails.name ? fbDetails.name.replace(/ /g, '') : '',
    facebook_email: fbDetails.email,
    email: fbDetails.email ? fbDetails.email : '',
    fb_id: fbDetails.id,
    password: CONSTANTS.LOGIN.VARIABLES.DEFAULT_PASSWORD,
    user_image: CONSTANTS.LOGIN.VARIABLES.DEFAULT_IMAGE,
    gender: fbDetails.gender ? fbDetails.gender : CONSTANTS.LOGIN.VARIABLES.GENDERS.MALE,
    relationship: fbDetails.relationship_status
      ? fbDetails.relationship_status
      : CONSTANTS.LOGIN.VARIABLES.RELATIONSHIP_STATUS.UNKNOWN,
    date_of_birth: fbDetails.birthday ? fbDetails.birthday : '',
    fb_image: fbDetails.picture ? fbDetails.picture.data.url : '',
    phone_number: fbDetails.phoneNumber
  };
}

async function updateProfile(data, tokenData) {
  const tokenInfo = tokenData;
  const token = tokenInfo ? tokenInfo.token : null;
  if (!token) {
    return null;
  }

  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.PROFILE}`, {
    method: 'POST',
    body: JSON.stringify({ ...data, user_id: tokenData.userId }),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });

  response = await response.json();
  if (response && response.data) return response.data;
  throw new Error('Could not update profile');
}
async function sendMail(data, tokenData) {
  const tokenInfo = tokenData;
  const token = tokenInfo ? tokenInfo.token : null;
  if (!token) {
    return null;
  }

  let response = await fetch(`${CONSTANTS.NODE_API_URL}${CONSTANTS.URLS.LOGIN.SEND}`, {
    method: 'POST',
    body: JSON.stringify({ ...data, user_id: tokenData.userId }),
    headers: { ...CONSTANTS.REQUEST_HEADERS, 'X-ACCESS-TOKEN': token }
  });

  response = await response.json();
  if (response && response.data) return response.data;
  throw new Error('Could not send your mail.');
}
