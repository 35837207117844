import CONSTANTS from '../../constants';

export function items(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ITEMS.LIST) {
    return action.items;
  }
  return state;
}

export function cat_items(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ITEMS.LIST_CAT) {
    return action.cat_items;
  }
  return state;
}
