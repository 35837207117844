import { combineReducers } from 'redux';
import { error } from './alerts/ErrorReducer';
import { toast } from './alerts/ToastReducer';
import { bindiscounts } from './bindiscounts/BindiscountsReducer';
import { cart } from './cart/CartReducer';
import { categories } from './categories/CategoriesReducer';
import { statuses } from './configurations/StatusesReducer';
import { item, itemDetails } from './items/ItemReducer';
import { cat_items, items } from './items/ItemsReducer';
import { popularItems } from './items/PopularItemsReducer';
import { childLocations } from './locations/ChildLocationsReducer';
import { location, page } from './locations/LocationReducer';
import { locationSettings } from './locations/LocationSettingsReducer';
import { nearByLocations } from './locations/NearByLocationsReducer';
import { earnedrewards } from './loyalties/EarnedReducer';
import { loyalties } from './loyalties/LoyaltyReducer';
import { notifications } from './notifications/NotificationsReducer';
import { createdOrder, orderTimes, orders } from './orders/OrderReducer';
import { processor } from './payments/ProcessorReducer';
import prefModalReducer from './prefModal/PrefModalReducer';
import { promotions } from './promotions/PromotionsReducer';

export default combineReducers({
  toast,
  error,
  location,
  page,
  categories,
  items,
  cat_items,
  itemDetails,
  popularItems,
  item,
  cart,
  createdOrder,
  orders,
  orderTimes,
  nearByLocations,
  childLocations,
  processor,
  statuses,
  promotions,
  bindiscounts,
  notifications,
  locationSettings,
  prefModalReducer,
  loyalties,
  earnedrewards,
});
