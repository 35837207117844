import CONSTANTS from '../../constants';

const initialState = {
  orderTypeId: null,
  orderType: null,
  cartState: null
};

const prefModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ACTIONS.ORDER_PREFERENCE.SET_ORDER_PREF_ID:
      return {
        ...state,
        orderTypeId: action.data
      };
    case CONSTANTS.ACTIONS.ORDER_PREFERENCE.SET_ORDER_PREF_TYPE:
      return {
        ...state,
        orderType: action.data
      };
    case CONSTANTS.ACTIONS.ORDER_PREFERENCE.SET_ORDER_PREF_CART_STATE:
      return {
        ...state,
        cartState: action.data
      };
    default:
      return state;
  }
};

export default prefModalReducer;
