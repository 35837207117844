import CONSTANTS from '../../constants';

export function createdOrder(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ORDERS.CREATE) return action.order;
  return state;
}

export function orders(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ORDERS.STATUS) return action.orders;
  return state;
}
export function order(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ORDERS.ORDER) return action.order;
  return state;
}

export function orderTimes(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.ORDERS.STATUS) return action.orderTimes;
  return state;
}
