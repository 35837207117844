import CONSTANTS from '../../constants';

export function earnedrewards(state = null, action) {

  if (action.type === CONSTANTS.ACTIONS.LOYALTIES.GET_EARNED_REWARDS) {
    const { earnedrewards } = action;

    return earnedrewards;
  }

  return state;
}
