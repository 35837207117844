import TagManager from 'react-gtm-module';
import CONSTANTS from '../constants';

export default {
  logEvent,
  logRestaurantEvent
};

function initializeAnalytics() {
  const tagManagerArgs = {
    gtmId: CONSTANTS.TRACKING_CODE
  };
  window.analytics = TagManager.initialize(tagManagerArgs);
}

function initializeResAnalytics(code) {
  const tagManagerArgs = {
    gtmId: code
  };
  window.analytics = TagManager.initialize(tagManagerArgs);
}

function logEvent(eventName, data = {}, options = {}) {
  window.analytics || initializeAnalytics();
  window.dataLayer.push(data);
}

function logRestaurantEvent(code, data = {}) {
  window.analytics || initializeResAnalytics(code);
  window.dataLayer.push(data);
}