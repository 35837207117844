import CONSTANTS from '../../constants';

export function childLocations(state = null, action) {
  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.CHILDREN) return action.childLocations;

  if (action.type === CONSTANTS.ACTIONS.LOCATIONS.OUTLETS) {
    const outlets = action.outlets.map(outlet => {
      const { OutletStockStatuses } = outlet;
      const stockStatuses = {};
      const categoryStatuses = {};

      OutletStockStatuses.forEach(status => {
        if (status.is_category) {
          categoryStatuses[status.menu_id] = status;
        } else {
          stockStatuses[status.menu_id] = status;
        }
      });

      return {
        ...outlet,
        stockStatuses,
        categoryStatuses,
      };
    });

    return outlets;
  }

  return state;
}
